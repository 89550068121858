import React from 'react';
import styled from 'styled-components';
import { Col, Card, Avatar } from 'ray';
import { Row } from '../App.style';

const DisplayPicture = styled(Avatar)`
  width: 120px;
  height: 120px;
`;

const SupporterCard = ({ className, name, tag }) => (
  <Col lg={3} md={4} sm={6} xs={12} className={`${className} pb-3`}>
    <Card className="shadow-lg pt-3 pb-4 rounded-sm text-center">
      <DisplayPicture url={`avatars/${tag || name}.png`} className="mx-auto mb-3" />
      <div className="text-lg">{name}</div>
      <div className="font-weight-light text-sm">@{tag || name}</div>
    </Card>
  </Col>
);

const Supporters = () => (
  <Row className="py-4 mx-2">
    <SupporterCard name="GivingTuesday" tag="givingtuesday" />
    <SupporterCard name="Comic Relief" tag="comicrelief" />
    <SupporterCard name="Beth Kanter" tag="kanter" />
    <SupporterCard name="WWF UK" tag="WWF_UK" />
    <SupporterCard className="desktop-only" name="Samaritans" />
    <SupporterCard className="desktop-only" name="CharityComms" />
    <SupporterCard className="desktop-only" name="Miranda Hart" tag="Mermhart" />
    <SupporterCard className="desktop-only" name="NCVO" />
  </Row>
);

export default Supporters;
