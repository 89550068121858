import React from 'react';
import 'ray/src/scss/ray.scss';
import { Col, Icon, Button } from 'ray';
import How from './components/How';
import Statistics from './components/Statistics';
import Supporters from './components/Supporters';
import {
  Page,
  LightfulLogo,
  Header,
  TopBanner,
  Image,
  PageTitle,
  Row,
  Subheading,
  CircleLogo,
  SocialLogo,
  FullWidthImage,
  ComposeScreenshot,
} from './App.style';
import LatestTweets from './components/LatestTweets';

const App = () => {
  const isDesktop = Math.min(window.innerWidth, window.screen.width) > 768;
  const isMobile = Math.max(window.innerWidth) < 475;
  return (
    <Page className="bg-white">
      <Header className="d-flex flex-column">
        <TopBanner src={`${!isDesktop ? 'mobile_' : ''}top_banner.svg`} />
        <LightfulLogo src={'icons/lightful_logo.svg'} />
        <Image width={220} src="icons/reclaim_social_hashtag.svg" />
        <PageTitle className="mt-1 text-white">
          The time has come to reclaim social media for good.
        </PageTitle>
        <div className="mt-3 text-white">
          There's too much negativity on social media nowadays.
          <br />
          Let's reclaim social media for good.
        </div>
      </Header>
      <Row className="mt-4">
        <Col md={4} sm={12}>
          <h2>Enough is enough</h2>
          <div className="font-weight-light pt-1 pb-3">
            There is too much negativity on social media nowadays. It's become a place for trolls,
            anger and fake news.
          </div>
        </Col>
        <Col md={4} sm={12}>
          <h2>It's not all bad</h2>
          <div className="font-weight-light pt-1 pb-3">
            From <span className="font-weight-bold">#MeToo</span> to{' '}
            <span className="font-weight-bold">#GivingTuesday</span>, there are many inspiring
            campaigns to highlight the good use of social media.
          </div>
        </Col>
        <Col md={4} sm={12}>
          <h2>Let's do it</h2>
          <div className="font-weight-light pt-1 pb-3">
            If we get this right, we can change the face of social media. It's time to make social
            media a force for good.
          </div>
        </Col>
      </Row>
      <Subheading className="text-center">How you can get involved</Subheading>
      <How />
      <Subheading className="text-center">Listen to the Reclaim Social Podcast 🎙</Subheading>
      <div className="d-flex justify-content-center mt-3 mt-lg-0">
        <a
          href="https://open.spotify.com/show/38Mn902Mz8D67vqgVPzXTM"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CircleLogo className="mx-1" src="icons/spotify.svg" />
        </a>
        <a
          href="https://soundcloud.com/reclaimsocialpodcast"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CircleLogo className="mx-1" src="icons/soundcloud.svg" />
        </a>
        <a
          href="https://itunes.apple.com/gb/podcast/reclaim-social-podcast/id1458281730?mt=2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CircleLogo className="mx-1" src="icons/itunes.svg" />
        </a>
      </div>
      <Row className="py-4">
        <iframe
          title="Reclaim social podcast"
          src="https://open.spotify.com/embed-podcast/show/38Mn902Mz8D67vqgVPzXTM"
          width="100%"
          height="232"
          frameBorder="0"
          allowTransparency="true"
          allow="encrypted-media"
        />
      </Row>
      <Row className="justify-content-between p-3 bg-gray-100 pb-4 m-0">
        <Col md={6} sm={12}>
          <Image className="w-100" src="business_cards.png" />
        </Col>
        <Col md={5} sm={12} className="my-auto">
          <h2>Need more inspiration?</h2>
          <div className="pt-2 pb-3 font-weight-light">
            Facebook / Linkedin
            <br />
            Twitter
            <br />
            Instagram
            <br />
            GIFs / Videos
            <br />
            Keynote
            <br />
          </div>
          <a
            href="https://25877381.fs1.hubspotusercontent-eu1.net/hubfs/25877381/RS2023%20(final)-compressed.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button>Download assets</Button>
          </a>
        </Col>
      </Row>
      <Subheading className="text-center">What you delightful people are saying</Subheading>
      <LatestTweets />
      <Subheading className="text-center">
        #ReclaimSocial in numbers. <br />
        The campaign trended on Twitter
      </Subheading>
      <Statistics />
      <Subheading className="text-center">Some of our supporters</Subheading>
      <Supporters />
      <FullWidthImage
        className="mt-3"
        url={`${isMobile ? 'mobile_' : ''}reclaim.jpg`}
        height={540}
      />
      <Subheading className="text-center pb-lg-4">As seen in</Subheading>
      <Row className="justify-content-center justify-content-xl-between align-items-center pb-4">
        <Col className="p-2 flex-grow-0">
          <Image width={isDesktop ? 160 : 110} src="icons/forbes.png" alt="Forbes" />
        </Col>
        <Col className="p-2 flex-grow-0">
          <Image
            width={isDesktop ? 160 : 110}
            src="icons/theguardian.png"
            alt="The Guardian - voluntary sector network"
          />
        </Col>
        <Col className="p-2 flex-grow-0">
          <Image
            width={isDesktop ? 140 : 90}
            src="icons/charitydigitalnews.png"
            alt="Charity Digital News"
          />
        </Col>
        <Col className="p-2 flex-grow-0">
          <Image width={isDesktop ? 155 : 105} src="icons/ukfundraising.png" alt="UKFundraising" />
        </Col>
        <Col className="p-2 flex-grow-0">
          <Image width={isDesktop ? 160 : 110} src="icons/thirdsector.png" alt="Third Sector" />
        </Col>
      </Row>
      <Row className="justify-content-between pt-5 py-lg-4">
        <Col xl={4} md={6} sm={12} className="my-auto">
          <h2>Are you a charity or beyond profit?</h2>
          <div className="pt-2 pb-3 font-weight-light">
            If you want to better use social media as a force for good, sign up to our Lightful
            platform for free to raise more support and funds for your cause.
          </div>
          <a href="https://partners.lightful.com/signup?planId=08a5a564-86ad-4595-875a-60a3fa173325%20_blank">
            <Button className={!isDesktop && 'w-50 mb-3 text-base'}>Sign up</Button>
          </a>
        </Col>
        <Col md={6} sm={12} className="align-self-end">
          <ComposeScreenshot src="compose_screenshot.png" />
        </Col>
      </Row>
      <FullWidthImage
        url={`${isMobile ? 'mobile_' : ''}footer.svg`}
        height={isDesktop ? 400 : 335}
        className="mt-4 d-flex flex-column text-white justify-content-center align-items-center"
      >
        <Image src={'icons/lightful_logo.svg'} width={100} />
        <div className="text-lg py-3">Connect with us on social media</div>
        <div className="d-flex">
          <SocialLogo
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.facebook.com/Lightful/"
            className="rounded-circle bg-white mr-2 d-flex justify-content-center align-items-center"
          >
            <Icon name="facebook" size={34} />
          </SocialLogo>
          <SocialLogo
            rel="noopener noreferrer"
            target="_blank"
            href="https://twitter.com/lightful"
            className="rounded-circle bg-white mr-2 d-flex justify-content-center align-items-center"
          >
            <Icon name="twitter" size={34} />
          </SocialLogo>
          <SocialLogo
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.instagram.com/lightful/"
            className="rounded-circle bg-white mr-2 d-flex justify-content-center align-items-center"
          >
            <Icon name="instagram" size={30} />
          </SocialLogo>
          <SocialLogo
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.linkedin.com/company/lightful"
            className="rounded-circle bg-white mr-2 d-flex justify-content-center align-items-center"
          >
            <Icon name="linkedin" size={30} />
          </SocialLogo>
        </div>
        <div className="pt-4 text-base font-weight-light">Copyright 2021 All rights reserved</div>
      </FullWidthImage>
    </Page>
  );
};

export default App;
