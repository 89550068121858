import React from 'react';
import { Card as CardStyle, Col } from 'ray';
import { Row, Icon } from '../App.style';
import styled from 'styled-components';

const IconBackground = styled.a`
  background: linear-gradient(#6a38e1, #4614bc, #4513bb);
  min-height: 60px;
  min-width: 60px;
`;

const TitleImage = styled.img`
  height: 70px;
  width: 70px;
  @media (min-width: 769px) {
    height: 82px;
    width: 82px;
  }
`;

const Card = styled(CardStyle)`
  min-height: 460px;
  min-width: 270px;
  @media (min-width: 769px) {
    min-height: 540px;
  }
`;

const HowCard = ({ imgUrl, title, children }) => (
  <Col lg={4} sm={12} className="px-md-2 px-0">
    <Card className="shadow-lg py-4 px-3 rounded mb-3 mb-lg-0">
      <TitleImage className="rounded-circle mb-3" alt={title} src={imgUrl} />
      <h2>{title}</h2>
      <div className="pt-1 font-weight-light">{children}</div>
      <div className="d-flex mt-auto pt-2 mb-lg-1">
        <IconBackground
          rel="noopener noreferrer"
          href="https://twitter.com/intent/tweet?text=It%27s%20time%20to%20make%20social%20media%20more%20inspiring.%20Let%27s%20%23ReclaimSocial%20for%20good!%20reclaimsocial.com"
          target="_blank"
          className="rounded-circle mr-2 d-flex justify-content-center align-items-center"
        >
          <Icon name="twitter" size={36} theme="white" />
        </IconBackground>
        <IconBackground
          rel="noopener noreferrer"
          href="https://www.facebook.com/sharer/sharer.php?u=reclaimsocial.com&quote=It%27s%20time%20to%20make%20social%20media%20more%20inspiring.%20Let%27s%20%23ReclaimSocial%20for%20good!"
          target="_blank"
          className="rounded-circle mr-2 d-flex justify-content-center align-items-center"
        >
          <Icon name="facebook" size={32} theme="white" />
        </IconBackground>
        <IconBackground
          rel="noopener noreferrer"
          href="https://www.linkedin.com/sharing/share-offsite/?url=reclaimsocial.com"
          target="_blank"
          className="rounded-circle mr-2 d-flex justify-content-center align-items-center"
        >
          <Icon name="linkedin" size={35} theme="white" />
        </IconBackground>
      </div>
    </Card>
  </Col>
);

const How = () => (
  <Row className="mb-1 mt-3">
    <HowCard imgUrl="icons/organisations.png" title="Organisations">
      Tell us about what your doing and how social media has helped. Post on Twitter, Facebook and
      LinkedIn using the <span className="font-weight-bold">#ReclaimSocial</span> hashtag.
    </HowCard>
    <HowCard imgUrl="icons/people.png" title="People">
      Highlight some of the amazing work a good person or great cause is doing. Post on Twitter,
      Facebook and LinkedIn using the <span className="font-weight-bold">#ReclaimSocial</span>{' '}
      hashtag.
    </HowCard>
    <HowCard imgUrl="icons/inspire.png" title="Inspire Others">
      Spread the positivity. Post on Facebook, Twitter and LinkedIn using{' '}
      <span className="font-weight-bold">#ReclaimSocial</span> to raise awareness of the movement
      and to get more people involved. Let’s drown out all the negativity!
    </HowCard>
  </Row>
);

export default How;
