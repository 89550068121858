import React from 'react';
import styled from 'styled-components';
import { Col, Icon } from 'ray';
import { Row } from '../App.style';

const Stat = styled.h3`
  font-size: 60px;
`;

const Item = ({ stat, name, icon, iconSize, iconTheme }) => (
  <Col md={3} className="pt-3">
    <Stat>{stat}</Stat>
    <div className="d-flex justify-content-center align-items-center text-sm">
      <Icon className="mr-1" name={icon} size={iconSize} theme={iconTheme} />
      {name}
    </div>
  </Col>
);

const Statistics = () => (
  <Row className="text-center font-weight-light pb-lg-3">
    <Item stat="152M" name="Impressions" icon="arrowGrowth" iconSize={28} iconTheme="warning" />
    <Item stat="13042" name="Tweets" icon="twitter" iconSize={25} iconTheme="info" />
    <Item stat="99%" name="Positive sentiment" icon="like" iconSize={25} iconTheme="danger" />
    <Item stat="7509" name="People" icon="users" iconSize={23} iconTheme="warning" />
  </Row>
);

export default Statistics;
