import styled from 'styled-components';
import { Row as RowStyle, Icon as IconStyle } from 'ray';

export const Page = styled.div`
  font-weight: 500;
  color: #212529;
  line-height: 1.375rem;
`;

export const LightfulLogo = styled.img`
  position: absolute;
  top: 40px;
  left: 120px;
  @media (max-width: 1168px) {
    left: 30px;
  }
`;

export const Image = styled.img`
  width: ${(props) => props.width}px;
`;

export const ComposeScreenshot = styled.img`
  width: 620px;
  margin: 0 -30px;
  @media (max-width: 1168px) {
    width: 110%;
  }
`;

export const FullWidthImage = styled.div`
  background: url(${(props) => props.url});
  background-size: cover;
  min-width: 100vw;
  height: ${(props) => props.height}px;
`;

export const Header = styled(FullWidthImage)`
  padding: 130px 30px 130px 220px;
  position: relative;
  height: 600px;
  @media (max-width: 1168px) {
    padding: 100px 70px 70px;
    height: 560px;
  }
  @media (max-width: 768px) {
    padding: 100px 30px 70px;
  }
  & > div {
    z-index: 5;
  }
`;

export const TopBanner = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  min-width: 100vw;
  @media (max-width: 420px) {
    height: 100%;
  }
`;

export const PageTitle = styled.div`
  font-size: 2.8125rem;
  line-height: 2.8125rem;
  @media (min-width: 769px) {
    max-width: 990px;
    line-height: 4.875rem;
    font-size: 4.0625rem;
  }
`;

export const Row = styled(RowStyle)`
  font-size: 1.125rem;
  margin: 0 80px;
  @media (max-width: 768px) {
    margin: 0 30px;
    & > .desktop-only {
      display: none;
    }
  }
`;

export const Icon = styled(IconStyle)`
  &:hover {
    fill: white !important;
    opacity: 0.7;
  }
`;

export const Subheading = styled.div`
  font-size: 2.625rem;
  line-height: 3.125rem;
  padding: 70px 30px 30px;
  @media (max-width: 768px) {
    padding: 50px 30px 0;
  }
`;

export const CircleLogo = styled.img`
  width: 60px;
  height: 60px;
`;

export const SocialLogo = styled.a`
  width: 50px;
  height: 50px;
  & > svg {
    fill: #ff3b06 !important;
  }
  & > svg:hover {
    opacity: 0.7;
  }
`;
