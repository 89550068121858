import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import ky from 'ky';
import styled from 'styled-components';
import { Col, Card, Avatar, Icon } from 'ray';
import { Row } from '../App.style';

const RowContainer = styled(Row)`
    max-width: 100vw;
    overflow: hidden;
    @media (min-width: 769px) {
        ${(props) => {
          if (props.medialength > 3) {
            return 'max-height: 820px; flex-direction: column;';
          } else if (props.medialength > 0) {
            return 'max-height: 650px; flex-direction: column;';
          }
        }}
`;

const TweetContainer = styled(Col)`
  ${(props) => props.order && `order: ${props.order};`}
`;

const DisplayPicture = styled(Avatar)`
  height: 30px;
  width: 30px;
`;

const Username = styled.div`
  line-height: 14px;
`;

const TweetCard = styled(Card)`
  line-height: 1.1rem;
  border-radius: 2px 2px 20px 20px;
`;

const TwitterIcon = styled(Icon)`
  position: absolute;
  right: 20px;
`;

const Media = styled.img`
  height: 215px;
`;

const LatestTweets = () => {
  const [loading, setLoading] = useState(false);
  const [tweets, setTweets] = useState([]);

  useEffect(() => {
    async function getTweets() {
      setLoading(true);
      const response = await ky
        .get(
          'https://ti0vt9vk9j.execute-api.eu-west-1.amazonaws.com/default/reclaimsocialTwitterFeed',
          {
            searchParams: {
              q: '#reclaimsocial',
              count: 6,
            },
          }
        )
        .json();

      setTweets(response.statuses);
      setLoading(false);
    }

    getTweets();
  }, []);

  if (loading) return <div />;
  if (tweets.length === 0) {
    return null;
  }

  /* colors all hashtags, links and user mentions blue */
  const formatText = (text, entities) => {
    const strings = text.split(' ');
    const entityStrings = [
      ...entities.hashtags.map(({ text: hashtagText }) => hashtagText),
      ...entities.urls.map(({ url }) => url),
      ...entities.user_mentions.map(({ screen_name }) => screen_name),
    ];

    return strings.map((string, index) => {
      if (
        entityStrings.includes(string) ||
        entityStrings.includes(string.replace(/[^\w\d-]+/g, ''))
      ) {
        return (
          <span key={index} className="text-info">
            {string}{' '}
          </span>
        );
      } else return `${string} `;
    });
  };

  return (
    <RowContainer className="pt-4">
      {tweets.map(({ id_str, order, text, user, entities, created_at }, index) => {
        const media = entities.media
          ? entities.media.map((item) => ({
              display_url: item.display_url,
            }))
          : [];
        return (
          <TweetContainer
            order={order || index * 2}
            key={id_str}
            sm={12}
            md={6}
            xl={4}
            className="mb-4 px-md-2 px-0 text-sm"
          >
            <TweetCard className="shadow-lg">
              {media.length > 0 && <Media src={media[0].display_url} />}
              <div className="p-2">
                <div className="d-flex align-items-center">
                  <DisplayPicture url={user.profile_image_url_https} />
                  <Username className="ml-half">
                    <div>{user.name}</div>
                    <div className="font-weight-light text-xs">{user.screen_name}</div>
                  </Username>
                  <TwitterIcon size={30} name="twitter" theme="twitter" />
                </div>
                <div className="font-weight-normal py-1">{formatText(text, entities)}</div>
                <div className="text-gray-600 font-weight-normal text-xxs">
                  {format(new Date(created_at), 'h:mm a - MMM d, yyyy')}
                </div>
              </div>
            </TweetCard>
          </TweetContainer>
        );
      })}
    </RowContainer>
  );
};

export default LatestTweets;
